<template>
    <v-row>
        <v-col cols="12" v-for="(stu, i) in students" :key="i">
            <h3 class="h6 blue--text">{{stu.name}}</h3>
            <small>{{stu.student_regno}}</small>
            <v-form v-model="storeF">
                <v-simple-table>
                    <thead>
                        <tr>
                        <th>Total Days Present </th>
                        <th>Class/Form Teacher Remarks </th>
                        <th>School Head Remarks </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                        v-for="remark in records.filter((item)=>{return item.scs_id == stu.scs_id})"
                        :key="remark.remark_id"
                        >
                        <td>
                            <v-text-field type="number" onwheel="this.blur()"
                            v-model="remark.days_present" 
                            @change="emitForm" 
                            class="small-input" 
                            size="1" min="0" max="127"
                            outlined>
                            </v-text-field>
                        </td>                        
                        <td>
                            <v-text-field
                                v-if="editForm"
                                v-model="remark.form_teach_remark" 
                                @change="emitForm"
                                outlined
                            ></v-text-field>
                        </td>
                        <td>
                            <v-text-field
                                v-if="editForm"
                                v-model="remark.school_head_remark" 
                                @change="emitForm"
                                outlined
                            ></v-text-field>
                        </td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-form>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: 'RemarkEntry',
    props:[
        'students',
        'records',
    ],
    data () {
        return {
            storeF:true,
            editForm: true,
        }
    },
    mounted(){
        this.emitForm()
    },
    methods:{
        emitForm (){
            this.$emit('updatedRemarkRecords', this.records)
        },
    }
}
</script>

<style scoped>
    .small-input{
       width: 70px !important; 
    }
</style>