<template>
    <v-row>
        <v-col cols="12">
            <v-form>
                <v-container>
                <v-row>
                    <v-col cols="12" md="6">
                    <v-select
                    :items="terms.filter((item)=>{return item.current_term == true})"
                    item-text="term_session"
                    item-value="parameter_id"
                    label="Select Term:"
                    v-model="form.parameter_id"
                    @change="fetchStudents()"
                    outlined
                    :rules="[v => !!v || 'Term need to be selected!']"
                    required
                    ></v-select>
                    </v-col>

                    <v-col cols="12" md="6">
                    <v-select
                    :items="classes"
                    item-text="class"
                    item-value="class_id"
                    label="Select Class:"
                    v-model="form.clasx_id"
                    outlined
                    :rules="[v => !!v || 'Class need to be selected!']"
                    required
                    @change="fetchStudents()"
                    ></v-select>
                    </v-col>
                </v-row>
                </v-container>
            </v-form>
        </v-col>
    </v-row>
</template>

<script>
import axios from 'axios';
export default {
    name: 'FindMotorRecord',
    props:[
        'authToken',
        'accessToken', 
        'school',
        'terms',
        'classes',
    ],
    data () {
        return {
            students: [],
            form: {},
        }
    },
    methods:{
        emitForm (){
            if (this.form.parameter_id && this.form.clasx_id) { 
                this.$emit('parameterId', this.form.parameter_id)
                this.$emit('clasxId', this.form.clasx_id)
            }
        },
        fetchStudents (){
            if (this.form.parameter_id && this.form.clasx_id) { 
                let loader = this.$loading.show()
                axios.get(process.env.VUE_APP_BASEURL+'/students?school_id='+this.school.school_id+'&class_id='+this.form.clasx_id+'&parameter_id='+this.form.parameter_id,
                {
                    headers: 
                    {
                        'Authorization': 'Bearer '+this.authToken,
                        'access_token': this.accessToken,
                    }
                })
                .then((response) => {
                    this.students =  response.data.data 
                    this.$emit('students', this.students) 
                    if (this.students.length == 0) {
                        this.$toast.info('No Students in selected class. ADD or PROMOTE students to class!', {
                                timeout: 5000
                            }); 
                        this.$router.push('students')
                    }  
                })
                .catch((error) => {
                    console.log(error)
                })
                .finally(() => {
                    this.emitForm()
                    loader.hide()
                });
            }
        },
    }
}
</script>

<style scoped>
</style>