<template>
  <v-row>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      persistent
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
            dark
            color="success"
            class="noprint"
            dense
            >
            <v-btn
                icon
                dark
                @click="dialog= false"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>COGNITIVE/PYSCHOMOTOR/ETC ENTRY</v-toolbar-title>
        </v-toolbar>
        <v-stepper v-model="e1">
            <v-stepper-header>
            <v-stepper-step
                :complete="e1 > 1"
                step="1"
            >
                Fetch Records
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
                :complete="e1 > 2"
                step="2"
            >
                Update Record
            </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
            <v-stepper-content step="1">
                <v-card
                class="mb-12"
                color="#fff"
                >                
                    <!-- Search Form -->
                    <find-motor-record :classes="classes" :terms="terms" :authToken="authToken" :accessToken="accessToken" :school="school" @students="studentRecords" @parameterId="selectedTerm" @clasxId="selectedClass"/>
                    
                </v-card>

                <v-btn
                :disabled="!fetchF"
                color="primary"
                @click="step2"
                >
                Continue
                </v-btn>

                <v-btn text @click="dialog = false">
                Close
                </v-btn>
            </v-stepper-content>

            <v-stepper-content step="2">
                <v-card 
                v-if="records"
                class="mb-12"
                color="#fff"
                >
                    <v-card-title>Motor Entry Form</v-card-title>
                    <v-card-subtitle class="caption">Showing records for Class: <strong>{{records.class.class}}</strong> for the <strong>{{records.term.term_session}}</strong> Term Academic Session</v-card-subtitle>
                    <v-alert border="left" color="black" dismissible type="info" text>
                        Leave blank for students you dont want to record for
                    </v-alert>

                    <motor-entry v-if="reRenderComponent" :students="students" :records="records.data" @updatedMotorRecords="updatedMotors"/>
                
                </v-card>

                <v-btn
                color="primary"
                @click="updateMotors"
                >
                    <v-icon>mdi-content-save</v-icon>
                    Save &amp; Close
                </v-btn>
                <v-btn text @click="e1 = 1" color="red">
                    <v-icon>mdi-chevron-double-left</v-icon>
                    Back
                </v-btn>
                <v-btn text @click="e1 = 1" color="blue">
                    <v-icon>mdi-refresh</v-icon>
                    New Entry
                </v-btn>
            </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from 'axios';
import FindMotorRecord from './FindMotorRecord.vue';
import MotorEntry from './MotorEntry.vue';
    export default {
    components: { FindMotorRecord, MotorEntry },
        name: 'MotorModal',
        props:[
            'authToken', 
            'accessToken', 
            'school',
            'terms',
            'classes',
        ],
        data () {
            return {
                e1: 1,
                dialog: true,
                records: null,
                fetchF:false,
                storeF:true,
                students:null,
                parameter_id:null,
                clasx_id:null,
                student_ids:null,
                motorData:{
                    motors:null
                },
                reRenderComponent:true
            }
        },
        computed:{
           //
        },
        methods: {
            fetchMotors (){
                let loader = this.$loading.show()
                axios.get(process.env.VUE_APP_BASEURL+'/motors?school_id='+this.school.school_id+'&clasx_id='+this.clasx_id+'&parameter_id='+this.parameter_id,
                {
                    headers: 
                    {
                        'Authorization': 'Bearer '+this.authToken,
                        'access_token': this.accessToken,
                    }
                })
                .then((response) => {
                    this.records =  response.data        
                })
                .catch((error) => {
                    this.$toast.error(error.response ? error.response.data.message : 'Network Error, Please try again few minutes', {
                        timeout: 5000
                    });
                })
                .finally(() => {
                    loader.hide()
                });
            },
            updateMotors (){
                if(confirm("Empty Records will not be saved. Are you sure you want to continue?")){
                    let loader = this.$loading.show()
                    axios.post(process.env.VUE_APP_BASEURL+'/motors?school_id='+this.school.school_id+'&clasx_id='+this.clasx_id+'&parameter_id='+this.parameter_id,this.motorData,
                    {
                        headers: 
                        {
                            'Authorization': 'Bearer '+this.authToken,
                            'access_token': this.accessToken,
                        }
                    })
                    .then((response) => {
                        this.$toast.success('Motors Saved Successfully', {
                            timeout: 5000
                        });
                        console.log(response.data.success)    
                        this.e1 = 1   
                    })
                    .catch((error) => {
                        this.$toast.error(error.response ? error.response.data.message : 'Network Error, Please try again few minutes', {
                            timeout: 5000
                        });
                    })
                    .finally(() => {
                        loader.hide()
                    });
                }
            },
            studentRecords (value){
                this.students = value
            },
            selectedTerm (value){
                this.parameter_id = value
            },
            selectedClass (value){
                this.clasx_id = value
                this.fetchF = true
            },
            updatedMotors (value){
                this.motorData.motors = value
            },
            step2 (){
                this.reRenderComponent = false;
                this.$nextTick(() => {
                // Add the component back in
                this.reRenderComponent = true;
                });
                this.fetchMotors()
                this.e1 = 2
            }
        }
  }
</script>

<style scoped>

</style>