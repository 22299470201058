<template>
    <v-row>
        <v-col cols="12" v-for="(stu, i) in students" :key="i">
            <v-card-title class="h6 blue--text">{{stu.name}}</v-card-title>
            <v-simple-table>
                <thead>
                    <tr>
                    <th>CA 1 </th>
                    <th v-if="school.kinds_of_ca > 1">CA 2 </th>
                    <th v-if="school.kinds_of_ca > 2">CA 3 </th>
                    <th v-if="school.kinds_of_ca > 3">CA 4 </th>
                    <th>Exam </th>
                    <th>Total</th>
                    <th>Grade</th>
                    <th>Subject Pos.#</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                    v-for="score in records.scores.filter((item)=>{return item.scs_id == stu.scs_id})"
                    :key="score.result_id"
                    >
                    <td>
                        <v-chip>{{ score.this_term_scores.ca1 }}</v-chip>
                    </td>
                    <td v-if="school.kinds_of_ca > 1">
                        <v-chip>{{ score.this_term_scores.ca2 }}</v-chip>
                    </td>
                    <td v-if="school.kinds_of_ca > 2">
                        <v-chip>{{ score.this_term_scores.ca3 }}</v-chip>
                    </td>
                    <td v-if="school.kinds_of_ca > 3">
                        <v-chip>{{ score.this_term_scores.ca4 }}</v-chip>
                    </td>
                    <td>
                        <v-chip>{{ score.this_term_scores.exam }}</v-chip>
                    </td>
                    <td ><v-chip color="#FFFF8D">{{ score.this_term_scores.total }}</v-chip></td>
                    <td><v-chip text color="#B2EBF2">{{ score.this_term_scores.grade }}</v-chip></td>
                    <td><v-chip color="#B9F6CA">{{ score.this_term_scores.computed_subject_position }}</v-chip></td>
                    </tr>
                </tbody>
            </v-simple-table>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: 'ScorePreview',
    props:[
        'authToken', 
        'accessToken',
        'school',
        'students',
        'records',
    ],
    data () {
        return {
        }
    }
}
</script>

<style scoped>
    .small-input{
       width: 50px !important; 
    }
</style>