<template>
    <v-alert
    type="warning"
    dense
    border="left"
    color="primary"
    >
    This feature is <strong>ONLY</strong> available to Schools on our PREMIUM Plan. Contact us to upgrade
    </v-alert>
</template>

<script>
export default {
    name: 'PremiumInfo'
}
</script>

<style scoped>

</style>